.header {
    background-color: $primaryBackgroundColor;
    position: sticky;
    top: 0;
    z-index: 100;
    padding: 15px 0;

    &--sticky {
        box-shadow: 0 6px 16px $shadowColor;
    }

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__logo {
        width: 64px;
        height: 64px;
        cursor: pointer;

        @include respond(mobile) {
            width: calc(32px + 0.5vw);
            height: calc(32px + 0.5vw);
        }
    }

    &__nav {
        display: flex;
        gap: 40px;
        align-items: center;

        &--opened {
            @include navRespond(mobile) {
                display: flex;
            }
        }

        &--closed {
            @include navRespond(mobile) {
                display: none;
            }
        }

        & .close__button {
            display: none;

            @include navRespond(mobile) {
                display: unset;
                position: absolute;
                top: 15px;
                right: 15px;
                font-size: 28px;
            }
        }

        @include navRespond(tablet) {
            gap: 15px;
        }

        @include navRespond(mobile) {
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            height: 100vh;
            padding-top: 50px;
            gap: 5px;
            background-color: $primaryBackgroundColor;
            flex-direction: column;
        }

        & a {
            font-weight: 800;

            @include navRespond(tablet) {
                font-size: calc(12px + 0.5vw);
            }
        }

        & a.active {
            border-bottom: 3px solid $accentColor;

            @include navRespond(mobile) {
                border-bottom: 3px solid transparent;
            }
        }

        & a.inactive {
            border-bottom: 3px solid transparent;
        }

        & a.active,
        & a.inactive {
            border-top: 3px solid transparent;
            padding: 6px 0;
            transition: 0.3s;
        }

        & a.inactive:hover {
            border-bottom-color: lighten($accentColor, 35%);

            @include navRespond(mobile) {
                border-bottom-color: transparent;
            }
        }
    }
    &__nav--mobile {
        display: none;

        @include navRespond(mobile) {
            display: unset;
        }

        & .header__burger {
            color: $accentColor;
            font-size: 28px;
        }
    }
}
