.tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    @include respond(mobile) {
        gap: 2px;
    }

    &__item {
        text-align: center;

        & img {
            width: 64px;
        }

        & span {
            font-size: 16px;
            border: 2px solid $primaryTextColor;
            border-radius: 10px;
            padding: 4px 15px;
            text-transform: uppercase;
        }
    }
}
