@mixin respond($breakpoint) {
    @if $breakpoint==tablet {
        @media only screen and (min-width: 601px) and (max-width: 900px) {
            @content;
        }
    }

    @if $breakpoint==mobile {
        @media only screen and (max-width: 600px) {
            @content;
        }
    }
}

@mixin navRespond($breakpoint) {
    @if $breakpoint==tablet {
        @media only screen and (min-width: 701px) and (max-width: 900px) {
            @content;
        }
    }

    @if $breakpoint==mobile {
        @media only screen and (max-width: 700px) {
            @content;
        }
    }
}
