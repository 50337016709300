body,
button,
textarea,
input {
    font-family: 'Nunito', sans-serif;
}

button {
    border: none;
    background: none;
    cursor: pointer;
    -webkit-tap-highlight-color: none;
}

body,
ul,
li,
a,
p,
h1,
h2,
h3,
h4,
button,
input,
textarea {
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 20px;
    color: $primaryTextColor;
}

a {
    text-decoration: none;
}

h2 {
    font-size: 50px;
    font-weight: 800;
    @include respond(tablet) {
        font-size: calc(40px + 0.4vw);
    }
    @include respond(mobile) {
        font-size: calc(30px + 0.4vw);
    }
}

strong {
    font-weight: 700;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 50px;
    @include respond(mobile) {
        padding: 0 15px;
    }
}

.hidden {
    opacity: 0;
}
