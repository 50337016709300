.modal {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 200;
    transform: scaleY(0.01) scaleX(0);
    animation: unfoldIn 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;

    &__background {
        flex-grow: 1;
        background-color: rgba(0, 0, 0, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__content {
        display: flex;
        flex-direction: column;
        padding: 30px 40px 40px 40px;
        background-color: white;
        border-radius: 25px;
        box-shadow: 0 0 16px $shadowColor;
        min-height: 400px;
        max-height: 90vh;
        width: 850px;
        box-sizing: border-box;
        overflow: scroll;
        transform: scale(0);
        animation: zoomIn 0.4s 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;

        @include respond(tablet) {
            width: 90%;
        }

        @include respond(mobile) {
            width: 100%;
            border-radius: unset;
            min-height: unset;
            max-height: unset;
            height: 100vh;
        }
    }

    &__close {
        text-align: right;
        font-size: 25px;
        margin-bottom: 15px;
        & :first-child {
            cursor: pointer;
        }
    }
    &--hidden {
        display: none;
    }
}

@keyframes zoomIn {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes unfoldIn {
    0% {
        transform: scaleY(0.005) scaleX(0);
    }
    50% {
        transform: scaleY(0.005) scaleX(1);
    }
    100% {
        transform: scaleY(1) scaleX(1);
    }
}
