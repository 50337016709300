.projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 0 50px 0;
    background-color: $secondaryBackgroundColor;

    @include respond(mobile) {
        padding: 50px 0;
    }

    &__title {
        margin-bottom: 20px;
    }

    &__gallery {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 50px;
        padding: 0 15px;

        @include respond(tablet) {
            grid-template-columns: 1fr;
        }

        @include respond(mobile) {
            grid-template-columns: 1fr;
            gap: 20px;
            padding: 20px 15px;
        }
    }
}
