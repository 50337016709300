.card {
    overflow: hidden;
    border: 8px solid white;
    box-shadow: 0 0 16px $shadowColor;
    position: relative;

    &__details {
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: rgba($color: $accentColor, $alpha: 0.9);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 3;
        padding: 10px 50px;
        transition: opacity 0.5s;
        cursor: pointer;
        text-align: center;

        @include respond(mobile) {
            padding: 10px 10px;
        }

        & .details__title {
            font-size: calc(20px + 0.5vw);
            font-weight: 700;
            margin-bottom: 10px;

            @include respond(mobile) {
                font-size: calc(14px + 0.5vw);
                margin-bottom: 0.9vw;
            }
        }

        & .details__education {
            font-size: calc(16px + 0.3vw);
            margin-bottom: 10px;
            opacity: 0.7;

            @include respond(mobile) {
                font-size: calc(13px + 0.5vw);
                margin-bottom: 0.9vw;
            }
        }

        & .details__description {
            margin-bottom: 15px;

            @include respond(mobile) {
                margin-bottom: 0.9vw;
            }
        }

        & .details__tags .tags__item span {
            border-color: white;
            font-size: calc(8px + 0.4vw);
            padding: 3px 9px;

            @include respond(mobile) {
                font-size: calc(6px + 0.9vw);
                padding: 3px 9px;
            }
        }

        & .details__tags {
            margin-bottom: 20px;
        }

        & .details__info {
            display: flex;
            align-items: center;
            gap: 10px;
            font-weight: 700;
        }
        & .details__description,
        & .details__info {
            font-size: calc(14px + 0.3vw);

            @include respond(mobile) {
                font-size: calc(12px + 0.5vw);
            }
        }
    }

    &__title {
        box-shadow: 0 0 7px rgba(0, 0, 0, 0.35);
        padding: 8px 0;
        font-size: calc(18px + 0.5vw);
        font-weight: 700;
        background-color: $accentColor;
        color: white;
        text-align: center;
        position: absolute;
        bottom: 15px;
        left: 0;
        right: 0;
        transition: opacity 0.5s;

        @include respond(mobile) {
            padding: 4px 0;
            font-size: calc(13px + 0.5vw);
        }
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.card-modal {
    &__title {
        font-size: 28px;
        font-weight: 700;
        margin-bottom: 20px;
        text-align: center;
    }

    &__content {
        display: flex;
        justify-content: space-between;
        gap: 50px;

        @include respond(tablet) {
            flex-direction: column;
            gap: 25px;
        }

        @include respond(mobile) {
            flex-direction: column;
            gap: 25px;
        }

        & h4 {
            font-size: 22px;
            font-weight: 600;
            margin-bottom: 8px;

            @include respond(tablet) {
                font-size: calc(18px + 0.3vw);
            }

            @include respond(mobile) {
                font-size: calc(16px + 0.3vw);
            }
        }

        & .card-modal__right-colon {
            flex-shrink: 0;
            text-align: center;
        }

        & p {
            @include respond(tablet) {
                font-size: calc(14px + 0.3vw);
            }

            @include respond(mobile) {
                font-size: calc(13px + 0.3vw);
            }
        }
    }
    &__context,
    &__links {
        margin-bottom: 25px;
    }

    &__links {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    &__technologies .tags {
        display: grid;
        grid-template-columns: 1fr 1fr;

        @include respond(tablet) {
            display: flex;
            justify-content: center;
        }

        @include respond(mobile) {
            display: flex;
            justify-content: center;
        }
    }
}
