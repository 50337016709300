.skills {
    padding-top: 100px;
    padding-bottom: 30px;

    @include respond(mobile) {
        padding-top: 50px;
    }

    & .slide__carousel {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &__title {
        text-align: center;
        margin-bottom: 20px;
    }

    & p {
        text-align: center;
    }

    & .carousel .slide {
        padding: 0 15px;

        @include respond(mobile) {
            padding: 0;
        }
    }

    & .slide__container {
        display: flex;
        flex-direction: column;
        background-color: $secondaryBackgroundColor;
        padding: 50px 40px 70px 40px;

        @include respond(tablet) {
            padding: 50px 20px 70px 20px;
        }

        @include respond(mobile) {
            padding: 30px 10px 50px 10px;
            flex-grow: 1;
        }
    }

    & .slide__title {
        margin-bottom: 40px;
        font-size: 24px;
        font-weight: 600;
        text-transform: uppercase;
        padding-bottom: 20px;

        @include respond(mobile) {
            font-size: calc(16px + 1vw);
            padding-bottom: 0;
            margin-bottom: 10px;
        }
    }

    & .slide__skillGroup {
        display: grid;
        grid-auto-columns: 1fr;
        grid-auto-flow: column;
        margin: 0 10%;
        gap: 25px;
        align-items: center;
        flex-grow: 1;

        @include respond(tablet) {
            gap: 0;
        }

        @include respond(mobile) {
            grid-auto-columns: unset;
            grid-auto-flow: unset;
            grid-template-columns: 1fr 1fr;
            gap: 10px;
        }
    }

    & .carousel .slide img {
        filter: saturate(80%);
        width: 8vw;

        @include respond(tablet) {
            width: 10vw;
        }

        @include respond(mobile) {
            width: 15vw;
        }
    }

    // OVERIDE Carousel component - BEGIN

    & .carousel .control-prev.control-arrow:before {
        border-right: 13px solid #000;
    }

    & .carousel .control-next.control-arrow:before {
        border-left: 13px solid #000;
    }

    & .carousel .control-arrow:before,
    .carousel.carousel-slider .control-arrow:before {
        margin: 0 10px;
        border-top: 13px solid transparent;
        border-bottom: 13px solid transparent;
    }

    & .carousel .control-arrow,
    .carousel.carousel-slider .control-arrow {
        opacity: 1;
    }

    & .carousel .control-dots .dot {
        box-shadow: none;
        background: #999;
    }

    & .carousel .control-dots .dot.selected {
        box-shadow: none;
        background: #000;
    }

    // OVERIDE Carousel component - END
}
