.button {
    display: inline-flex;
    gap: 10px;
    align-items: center;
    padding: 8px 25px;
    background-color: $accentColor;
    border-radius: 50px;
    color: white;
    font-weight: 800;
    transition: 0.3s;
    border: 2.5px solid $accentColor;
    cursor: pointer;

    @include respond(mobile) {
        font-size: calc(14px + 1vw);
    }

    &[disabled] {
        background-color: darkgrey;
    }

    &:hover :not([disabled]) {
        background-color: darken($accentColor, 7%);
    }

    &--bordered {
        background-color: transparent;
        border: 2.5px solid $accentColor;
        color: $accentColor;

        &:hover {
            background-color: $accentColor;
            color: white;
        }
    }
}
