.loader {
    display: flex;
    justify-content: center;
    align-items: center;

    &__img {
        width: 32px;
        animation: spin 1s linear infinite;
    }
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}
